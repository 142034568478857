$(function() {
    // $("input[type='submit']").on("click", validate);
    // キャンセル
    $("input[name='cancel']").click(function() {
        window.location = "/reserve/new_reservation";
    });

    $("#email").change(function() {
        validate();
    });

    checkCheckBox();
});

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function validate() {
    const $result = $("#result");
    const email = $("#email").val();
    $result.text("");

    if (validateEmail(email)) {
        $result.css("display", "none");
        $('input[name="check"]').attr("disabled", false);
    } else {
        $result.css("display", "block");
        $result.text("メールアドレスの形式は正しくりません。");
        $('input[name="check"]')
            .prop("checked", false)
            .change();
        $('input[name="check"]').attr("disabled", true);
    }
    return false;
}

function checkCheckBox() {
    $("#checkbox").change(function(e) {
        // e.preventDefault();
        if ($('input[name="check"]').is(":checked")) {
            $("#submit").attr("disabled", false);
        } else {
            $("#submit").attr("disabled", true);
        }
    });
}
